import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "crescent" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "elcyklar-från-crescent"
    }}>{`Elcyklar från Crescent`}</h1>
    <p>{`Crescent är ett av Sveriges mest uppskattade märken inom elcyklar, känt för sin kombination av innovation och driftsäkerhet. Varumärket erbjuder en mångfacetterad produktserie designad för att möta alla cyklisters behov, oavsett om det handlar om daglig pendling eller familjevänliga äventyr. Varje Crescent elcykel är framställd med stilren design och toppmodern teknik som inkluderar kraftfulla motorer och hållbara batterisystem, säkerställande av en komfortabel och energisnål körupplevelse. Modeller som Crescent Elina och Crescent Elora är särskilt populära för stadspendling och urbana upptäcktsfärder, medan Crescent Elder och Crescent Elda är idealiska för längre turer och mer komplexa sträckor. Välj Crescent och upplev en pålitlig, anpassningsbar cykelresa som kombinerar stil och funktion på bästa sätt.`}</p>
    <h2>Om Crescent Elcykel</h2>
    <p>Crescent har länge varit ett framstående namn inom cykelbranschen och etablerat sig som ett pionjärmärke inom elcyklar. Med en historia av innovation och kvalitet, strävar Crescent ständigt efter att leverera produkter som kombinerar modern teknologi med en tidlös design. Crescent elcykel är synonymt med hållbarhet och pålitlighet, och varje modell är noggrant konstruerad för att möta kraven från den moderna cyklisten. Oavsett om du är en daglig pendlare eller njuter av längre turer på fritiden, erbjuder Crescent lösningar anpassade efter dina behov.</p>
    <p>Med fokus på både prestanda och komfort är Crescent elcyklar utrustade med avancerade motorer och batterisystem, vilket garanterar en smidig och kraftfull körupplevelse. Varumärkets åtagande för kvalitet syns i varje detalj, från robusta ramar till banbrytande teknik som förbättrar säkerheten och effektiviteten. Oavsett vilken typ av cykling du föredrar, kan du lita på att Crescent levererar en elcykel som är både pålitlig och njutbar, vilket gör varje resa till en upplevelse av glädje och frihet.</p>
    <h2>Elcykelserier från Crescent</h2>
    <p>Crescent erbjuder ett brett sortiment av elcyklar som täcker olika behov och preferenser hos cyklister. Först ut har vi <strong>Crescent Elast</strong>-serien, som är utformad med familjer i åtanke, perfekt för dem som behöver kombinera transport av barn och annan last med smidig eldrift. <strong>Crescent Elda</strong>, å andra sidan, är en hybridmodell som är optimerad för daglig pendling, med fokus på komfort och prestanda i både urban och landsomfattande miljöer. </p>
    <p><strong>Crescent Elder</strong> riktar sig till pendlare och utflyktsentusiaster, tack vare sin kraftfulla mittmotor och förmåga att leverera effektivitet på olika terränger. <strong>Elina</strong>-serien är den mest populära, känd för sin mångsidighet och lämplighet för både stadskärna och fritidscykling. För den stilmedvetna pendlare är <strong>Crescent Elis</strong> den perfekta partnern, med en elegant design och moderna pendlarvänliga funktioner. <strong>Elly</strong> är designad för både pendling och längre äventyr, medan <strong>Elmer</strong> kombinerar stil och prestanda, idealisk för både stad och landsväg.</p>
    <p><strong>Crescent Elora</strong> prisas för sin effektiva konstruktion och stilrena design, anpassad för både stad och land. Å andra sidan erbjuder <strong>Elsa</strong>-serien komfort och prestanda för både daglig användning och längre utflykter. Slutligen levereras en urbanvänlig design i <strong>Elska</strong> och <strong>Elston</strong> serierna, vilka kombinerar avancerad teknologi med smidighetsfaktorer för stadsmiljöer. Med så många alternativ tillgängliga, har Crescent något för varje typ av cyklist.</p>
    <h2>Crescent Elast</h2>
    <p>Upptäck Crescent Elast-serien, den ultimata elcykeln för aktiva familjer och bekväm lasttransport. Denna serie är noggrant utformad för att stödja både familjeresor och transport av diverse laster, vilket gör den till den ideala följeslagaren för daglig användning. Med en robust 20-tums konstruktion erbjuder Crescent Elast flexibla transportlösningar som rymmer upp till två barn på ett säkert sätt, kompletterat med stabila Shimano EP6-motorer för kraftfull assistans oavsett terräng.</p>
    <p>Säkerhetsfunktionerna är prioriterade i denna serie; skivbromsar av toppkvalitet från Shimano säkerställer pålitliga stopp även under krävande förhållanden, medan den inbyggda LED-belysningen ger suverän synlighet under mörka kvällar. Crescent Elast-serien underlättar för familjer att njuta av trygg och bekväm cykling, oavsett om det handlar om pendling, transport av barn, eller utforskande av stads- och landsvägsmiljöer. Välj Crescent Elast för en cykelupplevelse där säkerhet och bekvämlighet går hand i hand.</p>
    <h2>Crescent Elda</h2>
    <p>Crescent Elda-serien representerar en imponerande hybrid elcykel, perfekt anpassad för daglig pendling och urbana äventyr. Denna serie har utvecklats med fokus på att kombinera komfort och prestanda, vilket gör den till ett pålitligt val för cyklister som söker smidig transport i stadsmiljöer. Crescent Elda är utrustad med en kraftfull Shimano E6100 motor och ett robust 500 Wh batteri, vilket erbjuder oöverträffad pålitlighet och energieffektivitet — en riktig Crescent elcykel för pendling.</p>
    <p>Med sina 10 växlar och dämpade framgaffel navigerar du enkelt genom olika typer av underlag, från stadens livliga gator till lugnare landsvägar. Cykeln har också smart integrerade kablar och en tydlig LCD-display som ger dig full kontroll över cykelsystemet, samtidigt som dess stilrena design och funktionalitet gör den till en praktisk och elegant följeslagare. För pendlare är Crescent Elda elcykel den ultimata lösningen för att öka både bekvämlighet och cykelglädje i vardagen.</p>
    <h2>Crescent Elder</h2>
    <p>Crescent Elder-serien utmärker sig som den optimala hybrid-elcykeln för både pendling och längre utflyktsäventyr. Seriensen är kännetecknad av sin kraftfulla Shimano STEPS-mittmotor, vilket ger en mjuk och jämn körning, oavsett terräng. Den är designad för att leverera enastående effektivitet och komfort, vilket gör den idealisk för daglig användning i varierande miljöer. Med en robust ramkonstruktion och integrerade funktioner, som pålitliga skivbromsar och ett smart LCD-display, erbjuder Crescent Elder en säker och bekväm upplevelse. Den imponerande räckvidden på batteriet ger frihet att utforska utan oro för laddningen, vilket gör den perfekt för den moderna cyklisten som söker både prestanda och tillförlitlighet i en Crescent Elder elcykel.</p>
    <h2>Crescent Elina</h2>
    <p>Crescent Elina-serien är en av de mest populära elcyklarna på den svenska marknaden, känd för sin mångsidighet och tillförlitlighet. Med en stilren design och kraftfull framhjulsmotor från EGOING, erbjuder Elina en perfekt balans mellan elegans och funktionalitet. Den är särskilt lämpad för stadspendling tack vare sitt låga insteg och bekväma körupplevelse, men klarar även av längre, mer rekreationella turer på landsvägar. Utrustad med 7 växlar, skivbromsar och fotbroms, ger Crescent Elina både säkerhet och flexibilitet oavsett om du navigerar genom stadens gator eller cyklar på landet. Elina-serien är verkligen det självklara valet för den som söker en Crescent elcykel som kombinerar prestanda med stilfull användarvänlighet.</p>
    <h2>Crescent Elis</h2>
    <p>Crescent Elis-serien lyfter elcykelupplevelsen med sitt stilrena hybridkoncept som smidigt förenar modern teknologi med funktionalitet för den dagliga pendlaren. En Crescent Elis elcykel är inte bara en transportlösning, utan en stilren partner under varje färd. Med sitt eleganta utseende och den lätta aluminiumramen utrustad med Shimano STePS E6100 mittmotor, erbjuder denna elcykel en kraftfull och effektiv motorassistans för både stadstrafik och längre cykelrundor. Den imponerande räckvidden på upp till 7 mil säkerställer att du alltid når ditt mål med lätthet.</p>
    <p>Crescent Elis hybriddesign gör cykeln extremt användarvänlig, vilket kompletteras av en integrerad LCD-display och ett enkelt växlingssystem med 9 växlar, vilket ger en smidig övergång mellan olika terränger. Förbättrad komfort och säkerhet garanteras med hydrauliska skivbromsar och integrerad belysning, vilket gör Elis till det perfekta valet för den medvetna cyklisten som söker både estetik och funktionalitet. Med Crescent Elis får du inte bara en välbalanserad åktur utan också en investering i framtidens cykelteknologi.</p>
    <h2>Crescent Elly</h2>
    <p>Upplev friheten med Crescent Elly-serien, en lysande stjärna inom hybrid-elcyklar, perfekt för både daglig pendling och längre turer. Elly-serien är designad för att erbjuda maximalt med komfort och säkerhet, vilket gör den till ett utmärkt val för moderna cyklister. Den kraftfulla EGOING mittmotorn erbjuder smidig och effektiv kraftöverföring, vilket möjliggör hastigheter upp till 25 km/h och en imponerande räckvidd på upp till 70 km. Den robusta konstruktionen med aluminiumram och hydrauliska skivbromsar från Shimano garanterar pålitlig stoppkraft i alla väder. Crescent Elly är inte bara en praktisk transportlösning—den förenar funktion med stil i varje pedaltramp. Med sin sportiga design, ergonomiska ram och välgenomtänkta detaljer är Crescent Elly-serien en trygg följeslagare, oavsett om resan går genom stadens gator eller över landsvägens vyer.</p>
    <h2>Crescent Elmer</h2>
    <p>Crescent Elmer-serien erbjuder en perfekt symbios av prestanda och stil, vilket gör den idealisk för både stadskörning och landsvägscykling. Med en kraftfull Shimano STEPS E6110 mittmotor och ett integrerat EGOING 400 Wh batteri, säkerställer Elmer en effektiv och energisnål resa längs både asfalt och grusvägar. Dessutom är denna elcykel utrustad med ett femväxlat Shimano Nexus-system som garanterar en smidig växling under alla förhållanden. De robusta Shimano-skivbromsarna och Herrmans MR4 LED-belysning förbättrar både säkerhet och synlighet oavsett tid på dygnet. Den eleganta orange designen och de ergonomiska funktionerna som premiumdäck och ergonomisk sittposition gör Crescent Elmer till ett stilfullt och funktionellt val för den moderna cyklisten. Välj Crescent Elmer för en avancerad cykelupplevelse som kombinerar bekvämlighet med exceptionell prestanda.</p>
    <h2>Crescent Elora</h2>
    <p>Crescent Elora-serien erbjuder en stilfull och effektiv hybridelcykel designad för både stadsmiljö och landsbygd. Med sin högkvalitativa EGOING mittmotor levererar Elora en kraftfull och smidig åktur, vilket gör den idealisk för både daglig pendling och längre utflykter. Den robusta aluminiumramen och det strategiskt integrerade batteriet på 400 Wh säkerställer att du kan nå räckvidder upp till 70 km utan att kompromissa med prestanda, perfekt för alla terränger. Användarvänligheten är central i Eloras design, med funktioner som en tydlig TFT-display och lättåtkomliga växlar, vilket gör det enkelt att navigera under olika förhållanden. Dessutom garanterar Shimano-bromsarna och reflexutrustade Spectra-däck en trygg och säker cykling. Med Crescent Elora får du inte bara en elcykel utan en pålitlig partner som förvandlar varje resa till en njutbar upplevelse. Välj Crescent Elora för en elegant och högpresterande cykelupplevelse.</p>
    <h2>Crescent Elsa</h2>
    <p>Crescent Elsa-serien står ut med sin enastående mångsidighet och är designad för att leverera både komfort och prestanda, oavsett om det handlar om dagligt bruk eller längre utflykter. Utrustad med den kraftfulla Shimano STEPS-motorn erbjuder Elsa stabil och dynamisk elassistans, vilket gör varje cykelresa till ett rent nöje. Tack vare sitt låga insteg är dessa elcyklar särskilt lättillgängliga och användarvänliga, vilket ökar deras attraktionskraft för pendlare och fritidscyklister.</p>
    <p>Säkerhetsaspekterna är också ett stort plus för Crescent Elsa. Med effektiva bromssystem som inkluderar både fotbroms och skivbroms, säkerställer dessa cyklar pålitlig stoppkraft i alla situationer. Dess överlägsna belysningssystem ökar synligheten under svagt ljus, vilket garanterar en trygg cykelupplevelse dygnet runt. Upplev friheten med Crescent Elsa – där komfort och säkerhet samverkar för att förbättra din cykelsäsong året om.</p>
    <h2>Crescent Elska och Elston</h2>
    <p>Crescent Elska och Elston-serierna erbjuder en urban-vänlig design och högteknologiska komponenter som optimerar din cykling i stadsmiljöer. Crescent Elska imponerar med sin robusta konstruktion, utrustad med en kraftfull Shimano STePS E6100 motor och 27,5-tums hjul, vilket ger optimal stabilitet och körupplevelse i varierad terräng. Den är idealisk för den som söker en elcykel för pendling, tack vare avancerade funktioner som hydrauliska skivbromsar och punkteringssäkra däck som garanterar säkerhet och hållbarhet.</p>
    <p>Crescent Elston framhäver en elegant urban design med en effektiv Bosch Performance-motor och ett smart integrerat batteri som erbjuder en imponerande räckvidd på upp till 8 mil. Dess integrerade Bosch KIOX display och LED remote erbjuder intuitiv kontroll över assistansnivåer och batteristatus. Tillsammans med sin stilrena mattbruna finish och pålitliga bromssystem blir Crescent Elston en perfekt följeslagare för den dagliga stadspendlaren som värderar både komfort och teknik. Tillsammans ger dessa serier en oslagbar cykelupplevelse full av innovation och stil.</p>
    <h2>Köpguide för Crescent Elcyklar</h2>
    <p>När du väljer en <em>Crescent elcykel</em> är det viktigt att överväga din livsstil och de specifika behov du har. <em>Crescent</em> erbjuder ett brett utbud av elcykelserier för olika ändamål som säkerställer att du hittar den perfekta matchen.</p>
    <p>För den dagliga pendlaren som söker effektivitet och stil, erbjuder <em>Crescent Elda</em> och <em>Crescent Elder</em> modeller som båda kombinerar komfort med hög prestanda tack vare deras kraftfulla mittmotorer och intuitiva växelsystem. De är utmärkta val för såväl stadsvägar som mer varierad terräng.</p>
    <p>Om du behöver en elcykel <em>för familj</em> och lasttransport, är <em>Crescent Elast</em> det bästa valet. Med robust design och fokus på säkerhetsfunktioner, inklusive stabila skivbromsar och smart belysning, är dessa elcyklar idealiska för aktiva familjer.</p>
    <p>För hybridsökare erbjuder <em>Crescent Elly</em> och <em>Crescent Elora</em> en balans mellan pendling och utforskande äventyr, med design och säkerhetsfunktioner som skivbromsar och kraftfulla motorer för olika terränger.</p>
    <p>För styling och användarvänlighet i stadsområden, kan <em>Crescent Elska</em> och <em>Elston</em> museum-staden med stil och teknologi, tack vare sina ekologiska funktioner och avancerade komponenter. För den som älskar mångsidighet, är <em>Crescent Elsa</em> och <em>Crescent Elina</em>, med sina pålitliga prestanda och populära design för både stad och rekreation, utmärkta val.</p>
    <p>Välj en <em>Crescent cykel</em> som passar din cykelvana och livsstil, och upplev friheten av en elcykel designad för både komfort och prestanda.</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      